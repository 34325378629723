import { render, staticRenderFns } from "./elearningLogin.vue?vue&type=template&id=a28ab7ec&scoped=true&"
import script from "./elearningLogin.vue?vue&type=script&lang=js&"
export * from "./elearningLogin.vue?vue&type=script&lang=js&"
import style0 from "./elearningLogin.vue?vue&type=style&index=0&id=a28ab7ec&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a28ab7ec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VOtpInput } from 'vuetify/lib/components/VOtpInput';
installComponents(component, {VApp,VBtn,VCardText,VCardTitle,VOtpInput})
